let PROD_URL = 'https://api.fanfliq.com/v1/';
let VERSION_CODE = '1.0.0';

export default {
  PROD_URL: PROD_URL,
  VERSION_CODE: VERSION_CODE,
};

function getHostName() {
  return window.location.hostname;
}
