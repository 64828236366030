import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import logo from '../../images/logo-white.svg';
import close from '../../images/close-wt.svg';

const useStyles = makeStyles(theme => ({
  marginTop32: {
    marginTop: '2rem',
  },
  marginTop56: {
    marginTop: '3.5rem',
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      top: '2.5rem',
      right: '2rem',
      height: '2.5rem',
      width: '2.5rem',
    },
    marginLeft: 'auto',
    position: 'absolute',
    top: '2.5rem',
    right: '5.5rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  text: {
    color: '#fff',
    fontFamily: 'Rubik',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
    },
    fontStyle: 'normal',

    letterSpacing: '-0.05em',
  },
  textPre: {
    color: '#fff',
    fontFamily: 'Lato',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      marginLeft: '2rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
      marginLeft: '2rem',
    },
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  center: {
    background: '#AC423E',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingTop: '12.5rem',
      paddingBottom: '12.5rem',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '12rem',
      paddingRight: '12rem',
      paddingTop: '15.5rem',
      paddingBottom: '15.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '12rem',
      paddingRight: '12rem',
      paddingTop: '18.5rem',
      paddingBottom: '18.5rem',
    },
  },
  title: {
    color: '#AC423E',
    fontFamily: 'Lato',
    textAlign: 'left',
    fontSize: '16px',
  },
}));

const Greetings = props => {
  const classes = useStyles();

  return (
    <div style={{ background: '#AC423E' }}>
      <div className={`${classes.grow}`} onClick={props.onClose}>
        <img src={close} alt="logo" id="closegreeting" />
      </div>

      <div className={`${classes.center}`}>
        <img src={logo} alt="logo" />
        <Typography className={`${classes.text} ${classes.marginTop56}`}>
          Thank you for your message!
        </Typography>

        <Typography
          variant="h6"
          className={`${classes.textPre} ${classes.marginTop32}`}
        >
          We’ll get back to you soon :)
        </Typography>
      </div>
    </div>
  );
};

export default Greetings;
