export function getQuote() {
  return {
    quote: (
      <span>
        Great things in business are never done by one person.
        <br /> They are done by a team of people.
        <span style={{ color: '#39b666' }}>"</span>
      </span>
    ),
    qoute_by: 'Steve Jobs',
  };
}

export function services() {
  return ['APP', 'WEB'];
}

export function OurServiceList() {
  return [
    {
      id: 1,
      title: 'Design & research',
      img: require('../images/services/Design.svg').default,
      desc: 'We’ll help you solve problems your customers face to build better User Experience, usability and user flows.',
    },
    {
      id: 2,
      title: 'Development',
      img: require('../images/services/Development.svg').default,
      desc: (
        <span>
          We build products as per the client’s expectations considering the
          best designs and usability
          <br /> Don’t worry about your tech stack anymore.
        </span>
      ),
    },
    {
      id: 3,
      title: 'SDK Development',
      img: require('../images/services/SDKs.svg').default,
      desc: (
        <span>
          Are you in the audio tech space?
          <br />
          You can use our SDKs to build the best audio tech products.
          <br /> We’ll help you with the setup and maintenance
        </span>
      ),
    },
  ];
}

export function aboutUs() {
  return [
    {
      name: (
        <span>
          Akshay
          <br />
          Kheveria
        </span>
      ),
      role: (
        <span>
          Co-founder
          <br />& CEO
        </span>
      ),
      img: require('../images/aboutus/akshay.png'),
      lnkedin: 'https://www.linkedin.com/in/akheveria/',
    },
    {
      name: (
        <span>
          Proshanto
          <br />
          Gupta
        </span>
      ),
      role: (
        <span>
          Co-founder
          <br />& CTO
        </span>
      ),
      img: require('../images/aboutus/Proshanto.png'),
      lnkedin: 'https://www.linkedin.com/in/progupta/',
    },
    {
      name: (
        <span>
          Mahesh
          <br />
          Pawar
        </span>
      ),
      role: (
        <span>
          Backend
          <br />
          developer
        </span>
      ),
      img: require('../images/aboutus/mahesh.png'),
      lnkedin: 'https://www.linkedin.com/in/mahesh-pawar-116a12202/',
    },
  ];
}

export function industries() {
  return [
    {
      name: 'Audio Tech',
      lnkedin: 'https://www.linkedin.com/in/akheveria/',
    },
    {
      name: 'Audio Tech',
      lnkedin: 'https://www.linkedin.com/in/akheveria/',
    },
    {
      name: 'Audio Tech',
      lnkedin: 'https://www.linkedin.com/in/akheveria/',
    },
    {
      name: 'Mahesh Pawar',
      role: 'Backend developer',
      img: require('../images/aboutus/mahesh.png'),
      lnkedin: 'https://www.linkedin.com/in/mahesh-pawar-116a12202/',
    },
    {
      name: 'Keshav Saini',
      role: 'Lead Developer',
      linkedin: '',
    },
  ];
}

export function testimonial() {
  return [
    {
      name: 'Chetan Thakkar',
      role: 'Senior Director, Eqaro Guarantees',
      img: require('../images/aboutus/Chetan Thakkar.svg').default,
      initial: require('../images/“.svg').default,
      end: require('../images/”.svg').default,
      testimonial:
        'WDI team not only managed our existing platforms and improved on them but helped us create a robust, agile, much more capable & intelligent product grounds up and patiently handle our ever evolving needs and delivered. They never say “this can’t be done” No matter how complex your ask is - they always have a solution.  Thoroughly enjoy collaborating with WDI team.',
    },
    {
      name: 'Vivek Banka',
      role: ' CEO, GoalTeller',
      img: require('../images/aboutus/Vivek Banka.svg').default,
      initial: require('../images/“.svg').default,
      end: require('../images/”.svg').default,
      testimonial:
        'WeDigIT has been quite helpful in getting the initial UI/UX done for GoalTeller. They have also been very adaptable and despite a challenging environment, where the product was undergoing a massive evolution, managed to deliver. ',
    },
    {
      name: 'Harish batlapenumarthy',
      role: 'CEO, Emtropy Labs',
      img: require('../images/aboutus/Harish batlapenumarthy.svg').default,
      initial: require('../images/“.svg').default,
      end: require('../images/”.svg').default,
      testimonial:
        'Great communication and quality deliverables. Looking forward to working with WeDigIT again as soon as we can!',
    },
    {
      name: 'Pawan Mishra',
      role: 'Founder, Early App',
      img: require('../images/aboutus/Pawan Mishra.svg').default,
      initial: require('../images/“.svg').default,
      end: require('../images/”.svg').default,
      testimonial:
        'WeDigIT team was wonderful and utilized their expert skills in Android development to come up to speed on the project quickly and deliver successfully in the time originally scoped.',
    },
  ];
}

export function ourWorks() {
  return [
    {
      name: 'WeJammin',
      img: require('../images/services/WeJammin.svg').default,
      uiux: 'UI/UX',
      app: 'App',
      web: 'Web',
    },
    {
      name: 'OJAM',
      uiux: 'UI/UX',
      app: 'App',
      web: 'Web',
      img: require('../images/services/OJAM.svg').default,
    },
    {
      name: 'Jiya',
      app: 'App',
      web: 'Web',
      img: require('../images/services/Jiya.svg').default,
    },
    {
      name: 'Early',
      uiux: 'UI/UX',
      app: 'App',
      web: 'Web',
      img: require('../images/services/Early.svg').default,
    },
    {
      name: 'Eqaro',
      uiux: 'UI/UX',

      web: 'Web',
      img: require('../images/services/Equaro.svg').default,
    },
    {
      name: 'HAHAHAA APP',
      uiux: 'UI/UX',

      web: 'Web',
      img: require('../images/services/HAHAHAA APP.svg').default,
    },
    {
      name: 'ECHOLOKAL',
      uiux: 'UI/UX',
      web: 'Partnered with Oaktree apps',
      img: require('../images/services/EchoLokal.svg').default,
    },
    {
      name: 'EMTROPY',

      web: 'Web',
      img: require('../images/services/Emtropy.svg').default,
    },
    {
      name: 'Greenlane',
      uiux: 'UI/UX',
      app: 'App',
      web: 'Web',
      img: require('../images/services/Greenlane.svg').default,
    },
  ];
}

export function OurFocusList() {
  return [
    {
      id: 1,
      title: 'Creator Tools',
      img: require('../images/apps/creatertools.svg').default,
      desc: 'Create top quality content, be it a video or audio. We guarantee super easy, fast and user friend creator tools.',
    },
    {
      id: 2,
      title: 'Audio Tech Apps',
      img: require('../images/apps/Audio tech apps.svg').default,
      desc: 'We are best when it comes to playing with sounds. Our sound enhancement/modification engine can be used in music content, podcast and other audio tech apps',
    },
    {
      id: 3,
      title: 'Social Entertainment platforms',
      img: require('../images/apps/Social Entertainment platforms.svg').default,
      desc: 'Discover interest-driven social media. Meet new people sharing the same interests as yours and keep on expanding your horizons and create content in collaboration with others.',
    },
    {
      id: 4,
      title: 'Video Content Apps',
      img: require('../images/apps/Video Content Apps.svg').default,
      desc: 'Discover new videos every day, from anywhere in the world. We will guarantee an intense moment of laughter, inspiration and learning.',
    },
    {
      id: 5,
      title: 'Royalty free music library',
      img: require('../images/apps/Royalty free music library.svg').default,
      desc: (
        <span>
          Our creators are regularly posting their original content. Which turns
          into royalty free music for brands, marketing agencies etc.
          <br /> This music can be used for anything and anywhere!
        </span>
      ),
    },
  ];
}

export function ourFriends() {
  return [
    {
      img: require('../images/friends/wejammin.svg').default,
    },
    {
      img: require('../images/friends/ojam.svg').default,
    },
    {
      img: require('../images/friends/emtropy.svg').default,
    },
    {
      img: require('../images/friends/greenlane.svg').default,
    },
    {
      img: require('../images/friends/goalteller.svg').default,
    },
    {
      img: require('../images/friends/skizzlehr.svg').default,
    },
  ];
}

export function listCoreServices() {
  return [
    {
      title: 'App development',
      color: '#1d70a2',
      details:
        'Expertise in the characteristics and best practices unique to each native platform ensures that our custom apps exceed user expectations every time.',
    },
    {
      title: 'Web development',
      color: '#1d70a2',
      details:
        'The Web remains the most open and flexible runtime available today. We ensure that web applications exceed users expectations in the mobile age, including accessibility and responsive design.',
    },
    {
      title: 'Product Design',
      color: '#1d70a2',
      details:
        'User-centered design, where visual UI is informed by UX and content strategy, creates seamless and intuitive digital experiences.',
    },
    {
      title: 'Research + Insights',
      color: '#1d70a2',
      details:
        'Our practice bridges rich qualitative research with quantitative data to bring scientific rigor and a deep understanding of user needs to high-velocity software design and development.',
    },
    {
      title: 'Strategy + Innovation',
      color: '#1d70a2',
      details:
        'We cultivate a deep understanding of your industry, brand, customers, and vision in order to differentiate your product—and ultimately your brand—in the marketplace.',
    },
    {
      title: 'Cloud development',
      color: '#1d70a2',
      details:
        'Our practice bridges rich qualitative research with quantitative data to bring scientific rigor and a deep understanding of user needs to high-velocity software design and development.',
    },
  ];
}

//about us and
export function industriesofwork() {
  return [
    {
      name: 'Audio Tech',
      img: require('../images/industries/audio tech.svg').default,
    },
    {
      name: 'Gaming',
      img: require('../images/industries/gamming.svg').default,
    },
    {
      name: 'Fitness',
      img: require('../images/industries/fitness.svg').default,
    },
    {
      name: 'Fintech',
      img: require('../images/industries/fintech.svg').default,
    },
    {
      name: 'Real Estate',
      img: require('../images/industries/realestate.svg').default,
    },
    {
      name: 'HRMS',
      img: require('../images/industries/hrms.svg').default,
    },
    {
      name: 'Education',
      img: require('../images/industries/education.svg').default,
    },
    {
      name: (
        <span>
          Entertainm
          <br />
          ent
        </span>
      ),
      img: require('../images/industries/entertainment.svg').default,
    },
    {
      name: 'Retail',
      img: require('../images/industries/retail.svg').default,
    },
    {
      name: 'Blockchain',
      img: require('../images/industries/blockchain.svg').default,
    },
    {
      name: 'NFT',
      img: require('../images/industries/nft.svg').default,
    },
    {
      name: 'Health',
      img: require('../images/industries/health.svg').default,
    },
  ];
}

export function industriesofworkmob() {
  return [
    {
      name: 'Audio Tech',
      img: require('../images/industries/smaller/audiotech.svg').default,
    },
    {
      name: 'Gaming',
      img: require('../images/industries/smaller/gamming.svg').default,
    },
    {
      name: 'Fitness',
      img: require('../images/industries/smaller/fitness.svg').default,
    },
    {
      name: 'Fintech',
      img: require('../images/industries/smaller/fintech.svg').default,
    },
    {
      name: 'Real Estate',
      img: require('../images/industries/smaller/realestate.svg').default,
    },
    {
      name: 'HRMS',
      img: require('../images/industries/smaller/hrms.svg').default,
    },
    {
      name: 'Education',
      img: require('../images/industries/smaller/education.svg').default,
    },
    {
      name: 'Entertainment',
      img: require('../images/industries/smaller/entertainment.svg').default,
    },
    {
      name: 'Retail',
      img: require('../images/industries/smaller/retail.svg').default,
    },
    {
      name: 'Blockchain',
      img: require('../images/industries/smaller/bloackchain.svg').default,
    },
    {
      name: 'NFT',
      img: require('../images/industries/smaller/nft.svg').default,
    },
    {
      name: 'Health',
      img: require('../images/industries/smaller/health.svg').default,
    },
  ];
}

export function getIndustries() {
  return [
    'Ecommerce',
    'Audio/Video Editing',
    'Social Media and Entertainment',
    'Booking Platforms',
    'Team/Customers Management',
    'Games',
    'Financial Services',
    'Job Portal',
  ];
}

export function getComplementaryColor(hex) {
  var temprgb = hexToRgb(hex);
  var temphsv = RGB2HSV(temprgb);
  temphsv.hue = HueShift(temphsv.hue, 180.0);
  var rgb = HSV2RGB(temphsv);
  return rgbToHex(rgb.r, rgb.g, rgb.b);
}

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}
function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function RGB2HSV(rgb) {
  var hsv = new Object();
  var max = max3(rgb.r, rgb.g, rgb.b);
  var dif = max - min3(rgb.r, rgb.g, rgb.b);
  hsv.saturation = max == 0.0 ? 0 : (100 * dif) / max;
  if (hsv.saturation == 0) hsv.hue = 0;
  else if (rgb.r == max) hsv.hue = (60.0 * (rgb.g - rgb.b)) / dif;
  else if (rgb.g == max) hsv.hue = 120.0 + (60.0 * (rgb.b - rgb.r)) / dif;
  else if (rgb.b == max) hsv.hue = 240.0 + (60.0 * (rgb.r - rgb.g)) / dif;
  if (hsv.hue < 0.0) hsv.hue += 360.0;
  hsv.value = Math.round((max * 100) / 255);
  hsv.hue = Math.round(hsv.hue);
  hsv.saturation = Math.round(hsv.saturation);
  return hsv;
}

function HSV2RGB(hsv) {
  var rgb = new Object();
  if (hsv.saturation == 0) {
    rgb.r = rgb.g = rgb.b = Math.round(hsv.value * 2.55);
  } else {
    hsv.hue /= 60;
    hsv.saturation /= 100;
    hsv.value /= 100;
    var i = Math.floor(hsv.hue);
    var f = hsv.hue - i;
    var p = hsv.value * (1 - hsv.saturation);
    var q = hsv.value * (1 - hsv.saturation * f);
    var t = hsv.value * (1 - hsv.saturation * (1 - f));
    switch (i) {
      case 0:
        rgb.r = hsv.value;
        rgb.g = t;
        rgb.b = p;
        break;
      case 1:
        rgb.r = q;
        rgb.g = hsv.value;
        rgb.b = p;
        break;
      case 2:
        rgb.r = p;
        rgb.g = hsv.value;
        rgb.b = t;
        break;
      case 3:
        rgb.r = p;
        rgb.g = q;
        rgb.b = hsv.value;
        break;
      case 4:
        rgb.r = t;
        rgb.g = p;
        rgb.b = hsv.value;
        break;
      default:
        rgb.r = hsv.value;
        rgb.g = p;
        rgb.b = q;
    }
    rgb.r = Math.round(rgb.r * 255);
    rgb.g = Math.round(rgb.g * 255);
    rgb.b = Math.round(rgb.b * 255);
  }
  return rgb;
}

function HueShift(h, s) {
  h += s;
  while (h >= 360.0) h -= 360.0;
  while (h < 0.0) h += 360.0;
  return h;
}

function min3(a, b, c) {
  return a < b ? (a < c ? a : c) : b < c ? b : c;
}
function max3(a, b, c) {
  return a > b ? (a > c ? a : c) : b > c ? b : c;
}
