import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import ScrollToTop from './utilities/ScrollToTop';
import Loadable from 'react-loadable';
import './App.scss';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from './components/headercomponent/Header';
import { Container } from '@material-ui/core';
import { WavyContainer } from 'react-wavy-transitions';
import Lottie from 'react-lottie';
import animationData from './images/we_digIt.json';
const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#353535',
    },
    divider: '#39b666',
  },
  typography: {
    h1: {
      fontFamily: 'CeraPro',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '2rem',
      },
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.65,
      letterSpacing: 'normal',
      color: '#353535',
    },
    h4: {
      fontFamily: 'CeraPro',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '3.5rem',
      },
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.17,
      letterSpacing: 'normal',
      color: 'rgba(53, 53, 53, 0.06)',
    },
    h6: {
      fontFamily: 'CeraProRegular',
      fontSize: '20px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 1.6,
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily: 'CeraPro',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '1.8rem',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '1.8rem',
      },
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.65,
      letterSpacing: 'normal',
      color: '#353535',
    },
    body1: {
      fontFamily: 'CeraProRegular',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '0.9rem',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '0.9rem',
      },
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.65,
      letterSpacing: 'normal',
      color: '#353535',
    },
    subtitle1: {
      fontFamily: 'CeraProRegular',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '1.2rem',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '1.2rem',
      },
      fontWeight: 800,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      color: '#353535',
    },
  },
  overrides: {
    MuiTabs: {
      flexContainer: {
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
    MuiTab: {
      root: {
        margin: '10px 0px 10px 0px',
      },
      wrapper: {
        opacity: 0.7,
        marginLeft: 15,
        fontFamily: 'CeraPro',
        fontSize: '0.88rem',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        textTransform: 'none',
        letterSpacing: 0.42,
        color: '#000',
      },
    },
    MuiButton: {
      containedSecondary: {
        fontFamily: 'CeraProRegular',
        fontSize: '1rem',
        fontWeight: 800,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        color: '#fff',
        padding: '0.8rem 2.8rem 0.8rem 2.8rem',
        transitionDuration: '.3s',
        transitionProperty: 'transform',
        '&:hover': {
          transform: 'translateY(-5px)',
        },
      },
      outlinedPrimary: {
        fontFamily: 'CeraProRegular',
        fontSize: '1rem',
        fontWeight: 800,
        opacity: 0.87,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        borderWidth: 2,
        borderColor: '#fff',
        letterSpacing: 'normal',
        color: '#fff',
        padding: '0.4rem 3.5rem 0.4rem 3.5rem',
      },
    },
    MuiIconButton: {
      root: {
        transitionDuration: '.3s',
        transitionProperty: 'transform',
        '&:hover': {
          transform: 'translateY(-5px)',
        },
      },
    },
    MuiCard: {
      root: {
        cursor: 'pointer',
        transitionDuration: '.3s',
        transitionProperty: 'transform',
        '&:hover .card-arrow': {
          transition: 'transform .3s ease-in-out',
          transform: 'translateX(10px)',
        },
      },
    },
    MuiCardMedia: {
      root: {
        transitionDuration: '.6s',
        transitionProperty: 'transform',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
    },
    MuiToolbar: {
      root: {
        [defaultTheme.breakpoints.down('sm')]: {
          padding: '14px 0px 10px 0px',
        },
        [defaultTheme.breakpoints.up('md')]: {
          padding: '24px 0px 20px 0px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          padding: '24px 0px 20px 0px',
        },
      },
    },
    MuiTextField: {
      primary: {
        opacity: 0.87,

        color: '#fff',
        '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline':
          {
            borderColor: 'red', //default
          },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 40,
      },
    },
    MuiInput: {
      marginDense: {
        margin: '20px 0px 20px 0px',
      },
    },
  },
});

const loading = () => <p></p>;

const Home = Loadable({
  loader: () => import('./components/maincomponent/HomeComponent'),
  loading,
});

const Service = Loadable({
  loader: () => import('./components/maincomponent/ServiceComponent'),
  loading,
});
const AppsComponent = Loadable({
  loader: () => import('./components/maincomponent/AppsComponent'),
  loading,
});

const AboutUs = Loadable({
  loader: () => import('./components/maincomponent/AboutusComponent'),
  loading,
});

const Contactus = Loadable({
  loader: () => import('./components/maincomponent/ContactusComponent'),
  loading,
});

const routes = [
  { path: '/apps', name: 'Apps', Component: AppsComponent },
  { path: '/aboutus', name: 'AboutUs', Component: AboutUs },
  { path: '/services/:id', name: 'Services', Component: Service },
  { path: '/services', name: 'Services', Component: Service },
  { path: '/letstalk', name: 'Letstalk', Component: Contactus },

  { path: '/', name: 'Home', Component: Home },
];

const App = () => {
  const [headerColor, setHeaderColor] = useState('transparent');
  const [type, setType] = useState('home');
  const [check, setcheck] = useState(true);
  const HandleCheck = bool => {
    setcheck(bool);
  };
  const [loading, setLoading] = useState(true);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3100);
  }, []);
  return (
    <div>
      {loading ? (
        <div
          style={{
            backgroundColor: '#121212',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Lottie
            options={defaultOptions}
            style={{ height: '4.5rem', width: '10.5rem' }}
          />
        </div>
      ) : (
        <div>
          <WavyContainer />
          <BrowserRouter>
            <ScrollToTop>
              <div style={{ background: '#fff' }}>
                <Header bgColor={headerColor} type={type} check={check} />

                <Container className="container">
                  {routes.map(({ path, Component }) => (
                    <Route
                      key={path}
                      exact
                      path={path}
                      render={props => (
                        <div>
                          <Component
                            setType={setType}
                            setHeaderColor={setHeaderColor}
                            HandleCheck={HandleCheck}
                            {...props}
                          />
                        </div>
                      )}
                    />
                  ))}
                </Container>
              </div>
            </ScrollToTop>
          </BrowserRouter>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <WavyContainer />
      <BrowserRouter>
        <ScrollToTop>
          <div style={{ background: '#fff' }}>
            <Header bgColor={headerColor} type={type} check={check} />

            <Container className="container">
              {routes.map(({ path, Component }) => (
                <Route
                  key={path}
                  exact
                  path={path}
                  render={props => (
                    <div>
                      <Component
                        setType={setType}
                        setHeaderColor={setHeaderColor}
                        HandleCheck={HandleCheck}
                        {...props}
                      />
                    </div>
                  )}
                />
              ))}
            </Container>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
};

const exporter = () => (
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

export default exporter;
