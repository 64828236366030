import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { IconButton, Drawer } from '@material-ui/core';
import MoreIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '../../images/close.svg';
import Dialog from '@mui/material/Dialog';
import Slide from '@material-ui/core/Slide';
import Grow from '@mui/material/Grow';
import CssBaseline from '@material-ui/core/CssBaseline';
import ContactUs from '../contactuscomponent/ContactUs';
import { invertColor } from '../../utilities/utility';
import Stack from '@mui/material/Stack';
import logo from '../../images/logo-black-text.svg';
import { WavyLink } from 'react-wavy-transitions';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow timeout={2000} ref={ref} {...props} />;
});
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({ target: undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Header = props => {
  const [compColor, setComplemntaryColor] = useState('rgba(0,0,0)');

  const useStyles = makeStyles(theme => ({
    grow: {
      flexGrow: 1,
    },
    topRight: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: '2rem',
    },
    drawerPaper: {
      width: '100%',
      alignItems: 'left',
      justifyContent: 'center',
      padding: 0,
      margin: 0,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      paddingRight: '2rem',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuText: {
      background: 'transparent',
      borderWidth: '0px',
      fontFamily: 'Lato',
      fontSize: '0.875rem',
      fontWeight: '700',
      cursor: 'pointer',
      paddingBottom: '5px',
      margin: '0px 32px 0px 0px',
      color: compColor,
      '&:hover': {
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        cursor: 'pointer',
      },
    },
    menuTextMob: {
      background: 'transparent',
      borderWidth: '0px',
      fontFamily: 'Lato',
      fontSize: '1.5rem',
      fontWeight: '700',
      paddingBottom: '5px',
      margin: '0px 32px 0px 0px',
      color: '#121212',
      lineHeight: '4rem',
    },
    headertext: {
      opacity: 0.6,
      color: compColor,
      '&:after': {
        background: compColor,
      },
      '&:hover': {
        opacity: 1,
      },
    },
    headertextSelected: {
      opacity: 1,
      '&:after': {
        background: compColor,
        bottom: 0,
        width: '100%',
        content: '',
        display: 'block',
        height: '4px',
        left: 0,
        position: 'absolute',
      },
    },
    Logoimage: {
      position: 'absolute',
      top: 0,
      left: 0,
      margin: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2rem',
        marginTop: '2.5rem',
        height: '2.5rem',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '5rem',
        marginTop: '2.5rem',
        height: '3rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: '5rem',
        marginTop: '2.5rem',
        height: '3rem',
      },
    },
  }));

  const classes = useStyles();
  const menuId = 'primary-search-account-menu';
  const [current, setCurrent] = useState('home');
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    var color = props.bgColor ? props.bgColor : '#000';
    setComplemntaryColor(
      color.includes('#') ? invertColor(color, true) : '#fff',
    );
  }, [props.bgColor]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setDrawerOpen(true);
  };

  const toggleDrawer = open => {
    console.log('clicked');
    setDrawerOpen(false);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    toggleDrawer(false);
    console.log('opened');
  };

  const handleClose = () => {
    setOpen(false);
  };
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Drawer
      anchor="right"
      id={mobileMenuId}
      open={drawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
      type="temporary"
      onClose={handleMobileMenuClose}
    >
      <img
        src={logo}
        alt="logo"
        id="closegreeting"
        className={classes.Logoimage}
      />
      <img
        alt=""
        src={CloseIcon}
        className={classes.topRight}
        onClick={() => toggleDrawer(false)}
      />
      <div
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Stack style={{ paddingLeft: '2rem' }}>
          <div onClick={() => toggleDrawer(false)}>
            <WavyLink
              className={classes.menuTextMob}
              to="/aboutus"
              waveColor="#AC423E"
            >
              <span>ABOUT US</span>
            </WavyLink>
          </div>
          <div onClick={() => toggleDrawer(false)}>
            <WavyLink
              className={classes.menuTextMob}
              to="/Services"
              waveColor="#AC423E"
            >
              <span>SERVICES</span>
            </WavyLink>
          </div>
          <div onClick={() => toggleDrawer(false)}>
            <WavyLink
              className={classes.menuTextMob}
              to="/apps"
              waveColor="#AC423E"
            >
              <span>OUR APPS</span>
            </WavyLink>
          </div>
          <div className={classes.menuTextMob} onClick={handleClickOpen}>
            <span>LET'S TALK</span>
          </div>
        </Stack>
      </div>
    </Drawer>
  );

  const getClassNames = selected => {
    if (selected)
      return `${classes.headertext} ${classes.headertextSelected} head-text`;
    else return `${classes.headertext} head-text`;
  };

  const getLogoPath = (type = '') => {
    if (type === 'apps') return require('../../images/logo-white.svg').default;
    else if (type === 'letstalk' || compColor !== '#fff')
      return require('../../images/logo-black-text.svg').default;
    else return require('../../images/logo.svg').default;
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <ContactUs onClose={handleClose} />
      </Dialog>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar elevation={0} style={{ backgroundColor: props.bgColor }}>
          <Toolbar disableGutters>
            <WavyLink to="/" waveColor="#AC423E" className="logo-link">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setCurrent('home')}
              >
                <img
                  alt="logo"
                  src={getLogoPath(props.type)}
                  className="logo-bar"
                />
              </span>
            </WavyLink>
            <div className={classes.grow} />
            {props.check ? (
              <div className={classes.sectionDesktop}>
                <WavyLink
                  to="/aboutus"
                  waveColor="#AC423E"
                  className={`${classes.menuText} head`}
                >
                  <li>
                    <span className={getClassNames(props.type === 'aboutus')}>
                      ABOUT US
                    </span>
                  </li>
                </WavyLink>
                <WavyLink
                  to="/services"
                  waveColor="#AC423E"
                  className={`${classes.menuText} head`}
                >
                  <li>
                    <span className={getClassNames(props.type === 'services')}>
                      SERVICES
                    </span>
                  </li>
                </WavyLink>
                <WavyLink
                  to="/apps"
                  waveColor="#AC423E"
                  className={`${classes.menuText} head`}
                >
                  <li>
                    <span className={getClassNames(props.type === 'apps')}>
                      OUR APPS
                    </span>
                  </li>
                </WavyLink>
                <div
                  waveColor="#AC423E"
                  className={`${classes.menuText} head`}
                  onClick={handleClickOpen}
                >
                  <li>
                    <span className={getClassNames(props.type === 'letstalk')}>
                      LET'S TALK
                    </span>
                  </li>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className={classes.sectionMobile}>
              <IconButton
                aria-controls={menuId}
                onClick={handleMobileMenuOpen}
                color={compColor}
                style={{ padding: 0 }}
              >
                <MoreIcon
                  src={MoreIcon}
                  onClick={handleMobileMenuOpen}
                  style={{ color: compColor }}
                />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {renderMobileMenu}

      <Toolbar disableGutters />
    </div>
  );
};

export default Header;
