import React, { useState } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import logo from '../../images/logo-black-text.svg';
import close from '../../images/close.svg';

import API from '../../utilities/api/api';
import Greetings from '../contactuscomponent/Greetings.js';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade in={true} ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  marginTop32: {
    marginTop: '2rem',
  },
  marginTop56: {
    marginTop: '3.5rem',
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      top: '2.5rem',
      right: '2rem',
      height: '2.5rem',
      width: '2.5rem',
    },
    marginLeft: 'auto',
    position: 'absolute',
    top: '2.5rem',
    right: '5.5rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  Button: {
    marginTop: '3.2rem',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      marginLeft: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '2rem',
    },
  },
  marginTop60: {
    marginTop: '2.5rem',
  },

  text: {
    color: '#121212',
    fontFamily: 'Rubik',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
    },
    fontStyle: 'normal',

    letterSpacing: '-0.05em',
  },
  textPre: {
    color: '#000',
    fontFamily: 'Lato',
    fontSize: '56px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      marginLeft: '2rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
      marginLeft: '2rem',
    },
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  center: {
    background: '#F5FAFD',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingTop: '3.75rem',
      paddingBottom: '4.5rem',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8rem',
      paddingRight: '8.3rem',
      paddingTop: '4rem',
      paddingBottom: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '11rem',
      paddingRight: '13rem',
      paddingTop: '6rem',
      paddingBottom: '6rem',
    },
  },
  title: {
    color: '#AC423E',
    fontFamily: 'Lato',
    textAlign: 'left',
    fontSize: '16px',
  },
  TextField: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '2rem',
    },
  },
  Logoimage: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2rem',
      marginTop: '2.5rem',
      height: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '5rem',
      marginTop: '2.5rem',
      height: '3rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '5rem',
      marginTop: '2.5rem',
      height: '3rem',
    },
  },
}));

const CssTextField = styled(TextField)({
  fontFamily: 'Lato',
  fontSize: '12px',

  pading: '5px',
  width: '100%',
  '& label.Mui-focused': {
    color: '#5A6684',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#AEB4C2',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#5A6684',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#5A6684',
    },
    '& ::placeholder': {
      color: '#5A6684',
    },
  },
});

const ContactUs = props => {
  const classes = useStyles();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [requirement, setRequirement] = useState();

  const [openModal, setModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    if (email && requirement) {
      const body = {
        email: email,
        message: requirement,
      };

      API.post('lead', body)
        .then(res => {
          setOpen(true);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <div style={{ background: '#F5FAFD' }}>
      <img
        src={logo}
        alt="logo"
        id="closegreeting"
        className={classes.Logoimage}
      />
      <div className={`${classes.grow}`} onClick={props.onClose}>
        <img src={close} alt="logo" id="closegreeting" />
      </div>

      <div className={` ${classes.center} contactus`}>
        <Typography className={classes.text}>Let’s get connected</Typography>

        <Typography
          variant="h6"
          className={`${classes.textPre} ${classes.marginTop32}`}
        >
          <span style={{ color: '#AC423E', fontWeight: 600 }}> </span> We’d love
          to hear what you’re working on and figure out where we fit in.
          <br></br>
          Get in touch with us to discuss your project, request a quote, or even
          just pick our brains.<br></br> We’re always open to making new
          connections
        </Typography>

        <div
          style={{
            marginTop: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <form id="form" onSubmit={e => e.preventDefault()}>
            <div className={classes.TextField}>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                id="outlined-multiline-flexible"
                label="Message"
                variant="outlined"
                placeholder="Please fill your requirements here"
                value={requirement}
                multiline={true}
                onChange={e => setRequirement(e.target.value)}
                rows={3}
                id="detail"
              />
            </div>

            <div className={`${classes.TextField} ${classes.marginTop32}`}>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                id="outlined-multiline-flexible"
                label="Message"
                variant="outlined"
                margin="dense"
                type="email"
                placeholder="Fill your email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
                margin="dense"
                id="email"
                label="Email"
              />
            </div>
          </form>
          <div
            onClick={onSubmit}
            className={`${classes.TextField} ${classes.marginTop56}`}
          >
            <a className="btndark">
              <span className="btndark__circle" />
              <span className="btndark__dark-circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="icon-arrow-right"
                  viewBox="0 0 21 12"
                >
                  <path
                    fill="#fff"
                    d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
                  />
                </svg>
              </span>
              <span className="btndark__text">Submit</span>
            </a>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Greetings onClose={handleClose} />
      </Dialog>
    </div>
  );
};

export default ContactUs;
